import React from 'react'
import {CButton} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const SmsCampaignActions = (props) => {
  const { campaign, clone, cancel, edit } = props

  return (
    <div style={{display: 'flex'}}>
      <div style={{padding: '2px'}}>
        <CButton
          title="Edit Campaign"
          disabled={campaign.status !== 'scheduled'}
          variant="outline"
          shape="square"
          color="warning"
          size="sm"
          onClick={() => {
            edit(campaign)
          }}
        >
          <CIcon size="sm" name="cil-pencil"/>
        </CButton>
      </div>
      <div style={{padding: '2px'}}>
        <CButton
          title="Clone Campaign"
          variant="outline"
          shape="square"
          color="primary"
          size="sm"
          onClick={() => {
            clone(campaign)
          }}
        >
          <CIcon size="sm" name="cil-copy"/>
        </CButton>
      </div>
      <div style={{padding: '2px'}}>
        <CButton
          title="Cancel Campaign"
          disabled={campaign.status !== 'scheduled'}
          variant="outline"
          shape="square"
          color="danger"
          size="sm"
          onClick={() => {
            cancel(campaign)
          }}
        >
          <CIcon size="sm" name="cil-x-circle"/>
        </CButton>
      </div>
    </div>
  )
}

export default SmsCampaignActions
